import { StepDefinition, IStepDefinitionOptions, IOfflineStepDefinition, IOnlineStepDefinition, type ISyncOptions } from 'o365.pwa.modules.client.steps.StepDefinition.ts';
import { StoredProcedureProgress, type IStoredProcedureProgressJSON, type IStoredProcedureProgressOptions } from 'o365.pwa.modules.client.steps.StoredProcedureProgress.ts';
import { SyncStatus } from 'o365.pwa.modules.client.steps.StepSyncProgress.ts';
import { UIFriendlyMessage } from 'o365.pwa.modules.UIFriendlyMessage.ts';
import { type SyncType } from "o365.pwa.types.ts";
import { getOrCreateProcedure, type Procedure } from 'o365-modules';


export interface IStoredProcedureStepDefinitionOptions extends IStepDefinitionOptions {
    storedProcedureId: string;
    procedureParameters: object;
    onBeforeSync: OnBeforeSync;
    onAfterSync: OnAfterSync;
}

interface IOnBeforeSyncResponse {
    error: Error,
    title: string,
    body: string;
}

interface IOnAfterSyncResponse {
    error: Error,
    title: string,
    body: string;
}

type OnBeforeSync = (storedProcedure: Procedure, meomory: Object, procedureParameters: any) => Promise<IOnBeforeSyncResponse | void>;
type OnAfterSync = (storedProcedure: Procedure, meomory: Object, tables: { [key: string]: Array<any> }) => Promise<IOnAfterSyncResponse | void>;

export class StoredProcedureStepDefinition extends StepDefinition implements IOfflineStepDefinition<StoredProcedureProgress>, IOnlineStepDefinition<StoredProcedureProgress> {
    public readonly IOfflineStepDefinition = 'IOfflineStepDefinition';
    public readonly IOnlineStepDefinition = 'IOnlineStepDefinition';

    public readonly storedProcedureId: string;
    public readonly procedureParameters: object;
    public readonly onBeforeSync: OnBeforeSync;
    public readonly onAfterSync: OnAfterSync;

    constructor(options: IStoredProcedureStepDefinitionOptions) {
        super({
            stepId: options.stepId,
            title: options.title,
            dependOnPreviousStep: options.dependOnPreviousStep,
            vueComponentName: 'StoredProcedureProgress',
            vueComponentImportCallback: async () => {
                return await import('o365.pwa.vue.components.steps.StoredProcedureProgress.vue');
            }
        });

        this.storedProcedureId = options.storedProcedureId;
        this.procedureParameters = options.procedureParameters ?? {};
        this.onBeforeSync = options.onBeforeSync;
        this.onAfterSync = options.onAfterSync;
    }

    public toRunStepDefinition(): StoredProcedureStepDefinition {
        return new StoredProcedureStepDefinition({
            stepId: this.stepId,
            title: this.title,
            dependOnPreviousStep: this.dependOnPreviousStep,
            storedProcedureId: this.storedProcedureId,
            procedureParameters: this.procedureParameters,
            onAfterSync: this.onAfterSync,
            onBeforeSync: this.onBeforeSync
        })
    }

    generateStepProgress(options?: IStoredProcedureProgressJSON | IStoredProcedureProgressOptions, syncType?: SyncType): StoredProcedureProgress {
        return new StoredProcedureProgress({
            syncType: syncType,
            ...options ?? {},
            title: this.title,
            vueComponentName: this.vueComponentName,
            vueComponentImportCallback: this.vueComponentImportCallback
        });
    }

    public async syncOnline(options: ISyncOptions<StoredProcedureProgress>): Promise<void> {
        await this.onSync(options);
    }

    public async syncOffline(options: ISyncOptions<StoredProcedureProgress>): Promise<void> {
        await this.onSync(options);
    }

    private async onSync(options: ISyncOptions<StoredProcedureProgress> | ISyncOptions<StoredProcedureProgress>): Promise<void> {
        try {

            const storedProcedure = getOrCreateProcedure({ id: this.storedProcedureId, procedureName: this.storedProcedureId, timeout: 5 * 60 });
            const procedureParameters = Object.assign({}, this.procedureParameters);

            // ---- On Before Sync ---- //
            if (typeof this.onBeforeSync === 'function') {
                const onBeforeSyncResponse = await this.onBeforeSync(storedProcedure, options.memory, procedureParameters);

                if (typeof onBeforeSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onBeforeSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onBeforeSyncResponse.title, onBeforeSyncResponse.body));
                    return;
                }
            }


            options.stepProgress.procedureHasStarted = true;
            const tables = await storedProcedure.execute(procedureParameters);

            options.stepProgress.procedureHasStarted = false;
            options.stepProgress.procedureHasCompleted = true;

            // ---- On After Sync ---- //
            if (typeof this.onAfterSync === 'function') {
                const onAfterSyncResponse = await this.onAfterSync(storedProcedure, options.memory, tables);

                if (typeof onAfterSyncResponse === 'object') {
                    options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
                    options.stepProgress.errors.push(onAfterSyncResponse.error);
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', onAfterSyncResponse.title, onAfterSyncResponse.body));

                    return;
                }
            }
        } catch (error: any) {
            if (Array.isArray(error)) {
                for (var e of error) {
                    options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `${e.ErrorMessage}`));
                }
            } else {
                options.stepProgress.uiFriendlyMessages.push(new UIFriendlyMessage('ERROR', 'Something has gone wrong', `Try again or contact support if the issue does not get resolved. ${error}`));
            }
            options.stepProgress.procedureHasErrors = true;
            options.stepProgress.syncStatus = SyncStatus.SyncingWithErrors;
            options.stepProgress.errors.push(error);
        }
    }
}
